import React from "react";
import { useTranslation } from "react-i18next"
import '../HomePage.css'
import '../../common/styles/main.css'

const SocialLink = () => {
    const { t } = useTranslation();
    const handleClipBoardEmail = async (event) => {
        event.preventDefault();
        navigator.clipboard.writeText("akdlszkxm1019@gmail.com")
        alert('복사됨 Copied')
    }

    return (
        <section className="sections" id='social'>
            <h2>{t('HomePage_MainContent_sociallink')}</h2>
            <div>
                <a href="https://www.youtube.com/@ma_ca19">
                    <img src='https://www.youtube.com/s/desktop/c01ea7e3/img/logos/favicon_144x144.png' alt="no" />
                    <p>
                        Youtube
                        <span class="material-symbols-outlined">
                            open_in_new
                        </span>
                    </p>
                </a>
                <a href="https://open.kakao.com/me/ma_ca19">
                    <img src='https://t1.kakaocdn.net/kakaocorp/kakaocorp/admin/5f9c58c2017800001.png' alt="no" />
                    <p>
                        KakaoTalk
                        <span class="material-symbols-outlined">
                            open_in_new
                        </span>
                    </p>
                </a>
                <a href="#" onClick={handleClipBoardEmail}>
                    <img src="https://ssl.gstatic.com/ui/v1/icons/mail/rfr/gmail.ico" alt="no" />
                    <p>
                        Mail
                        <span class="material-symbols-outlined">
                            content_copy
                        </span>
                    </p>
                </a>
            </div>
        </section>
    )
}

export default SocialLink;