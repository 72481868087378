/*
    #LangSelectMenu
    #언어선택 메뉴
    2024-10-25
*/

import React, { useState, useEffect } from 'react';

const lang = { 'ko_KR': '한국어', 'en_US': 'ENGLISH' };

const LangSelectMenu = ({ i18n }) => {
    const [selected_lang, set_selected_lang] = useState('ko_KR');
    useEffect(() => {
        const SELL = localStorage.getItem('lang')
        console.warn(SELL)
        if (SELL) {
            i18n.changeLanguage(SELL);
            set_selected_lang(SELL)
        }
        else set_selected_lang('ko_KR')
    }, [selected_lang, i18n])


    const changeLanguage = () => {
        let lng = document.getElementById('langsel');
        console.warn(lng.value)
        i18n.changeLanguage(lng.value);
        localStorage.setItem('lang', lng.value)
    };

    return (
        <select id='langsel' onChange={changeLanguage}>
            {
                Object.keys(lang).map(i => (
                    i === selected_lang ? <option value={i} translate='no' selected> {lang[i]}</option> : <option value={i} translate='no'>{lang[i]}</option>
                ))}
        </select >
    )
}

export default LangSelectMenu;