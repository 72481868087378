import React, { useState, useEffect } from 'react';
import '../styles/Nav.css';
import '../styles/main.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DarkModeButton from './DarkModeButton';
import LangSelectMenu from './LangSelectMenu';

const Navbar = () => {
    const { i18n, t } = useTranslation();

    return (
        <nav className="navbar">
            <Link to={'/'}>
                <span translate='no' className="material-symbols-outlined">
                    home
                </span>
                {t('DOCT_MAIN_TITLE')}
            </Link>
            <div id='all-menu'>
                <Link to={'/addons'}>{t('download')}</Link>
                <a href='https://nonstop-plier-6f9.notion.site/16a986e2790f803bb312ccfce34218f9?v=16a986e2790f81f3984d000ce830d0a0'>{t('docs')}</a>
                <Link to={'/addons'}>{t('login')}</Link>
                <div id='settings'>
                    <DarkModeButton t={t} />
                    <LangSelectMenu i18n={i18n} />

                </div>
            </div>
        </nav>
    );
};

export default Navbar;
