/* eslint-disable no-unused-vars */
import React, { useState, useEffect, version } from "react";
import axios from "axios";
import { ip } from "../../../api";
import '../AddonDetailPage.css'


const VersionSelectModal = ({ addon_instances, onClose, code_name, t }) => {
    const [lastDownloadTime, setLastDownloadTime] = useState(0);
    const handleDownload = async (event) => {
        console.log('다운로드 로직');
        event.preventDefault();
        console.log('테스트: ')
        console.log(event.target.id)

        try {
            const currentTime = Date.now();
            if (currentTime - lastDownloadTime < 2000) {
                alert(`${(2000 - currentTime + lastDownloadTime) / 1000}초 후 다시 시도하세요`);
                return;
            }
            // eslint-disable-next-line no-restricted-globals
            if (!confirm(t('AddonDetailPage-Modal-table-term_alert'))) {
                return;
            }

            setLastDownloadTime(currentTime);
            const response = await axios.post(`https://${ip}/api/addons/download/`, { code_name: code_name, version: event.target.id }, {
                responseType: 'blob',
            });
            const contentDisposition = response.headers['content-disposition'];
            let filename = code_name + event.target.id + '[file name error](zip,mcaddon,mcpack,mcworld, etc.).mcaddon'; // Default name
            if (contentDisposition) {
                let filenameMatch = contentDisposition.match(/filename\*?=([^;]+)/i);
                if (filenameMatch) {
                    let filenamePart = filenameMatch[1].trim();
                    if (filenamePart.startsWith("UTF-8''")) {
                        filename = decodeURIComponent(filenamePart.replace("UTF-8''", ''));
                    } else {
                        filename = filenamePart.replace(/(^")|("$)/g, '');
                    }
                }
            }

            // 파일 다운로드
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); // 파일 이름 지정
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            const download_count = document.getElementById('download_count');
            download_count.innerHTML = parseInt(download_count.innerHTML) + 1;
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <div id="version_select_modal">
            <div>
                <div>
                    <h3>{t('AddonDetailPage-Modal-title')}</h3>
                    <table>
                        <tr>
                            <td>{t('AddonDetailPage-Modal-table-version')}</td>
                            <td>{t('AddonDetailPage-Modal-table-date')}</td>
                            <td>{t('AddonDetailPage-Modal-table-download')}</td>
                        </tr>
                        {addon_instances.map(i => (
                            <tr>
                                <td>{i.version}</td>
                                <td>{new Date(i.upload_date).toLocaleDateString()}</td>
                                <td>
                                    <button class="version_download" onClick={handleDownload}>
                                        <span class="material-symbols-rounded" id={i.version}>
                                            download
                                        </span>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
                <button id="moal_close" onClick={onClose}>&times;</button>
            </div>
        </div>
    )
}
export default VersionSelectModal;